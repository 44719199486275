.payment-link-invoice-new-layout-wrap {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/version-two-bg-inv-pay.png"), linear-gradient(to right, rgb(240, 255, 249), rgb(255, 250, 240));
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
}
@media only screen and (max-width: 45em) {
  .payment-link-invoice-new-layout-wrap {
    height: unset;
  }
}
.payment-link-invoice-new-layout-wrap::-webkit-scrollbar {
  display: none;
}
.payment-link-invoice-new-layout-wrap .size-wrap-layout {
  width: 80%;
}
@media only screen and (max-width: 45em) {
  .payment-link-invoice-new-layout-wrap .size-wrap-layout {
    width: 92%;
  }
}
.payment-link-invoice-new-layout-wrap .header-nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.15rem solid rgb(224, 241, 240);
  z-index: 4;
  background-image: linear-gradient(to right, rgb(240, 255, 249), rgb(255, 250, 240));
}
.payment-link-invoice-new-layout-wrap .header-nav .size-wrap-layout {
  display: flex;
  align-items: center;
  padding: 1.3rem 0rem;
  justify-content: space-between;
}
.payment-link-invoice-new-layout-wrap .header-nav .size-wrap-layout .btn-box {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}
@media only screen and (max-width: 45em) {
  .payment-link-invoice-new-layout-wrap .header-nav .size-wrap-layout .btn-box {
    gap: 1.5rem;
  }
}
.payment-link-invoice-new-layout-wrap .header-nav .size-wrap-layout .btn-box .dowload-btn {
  background-color: rgb(224, 241, 240);
  color: rgb(11, 131, 118);
  border-color: rgb(127, 199, 194) !important;
  border-width: 0.15rem;
}
.payment-link-invoice-new-layout-wrap .header-nav .size-wrap-layout .logo-box .img-box {
  width: 5rem;
  height: 5rem;
}
.payment-link-invoice-new-layout-wrap .header-nav .size-wrap-layout .logo-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-invoice-new-layout-wrap .content-layout {
  padding-top: 11rem;
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 1;
  flex: 1;
}
@media only screen and (max-width: 45em) {
  .payment-link-invoice-new-layout-wrap .content-layout {
    padding-top: unset;
    flex: unset;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout {
  display: flex;
  justify-content: center;
  flex: 1;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap {
  width: 45%;
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 75em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap {
    width: 80%;
  }
}
@media only screen and (max-width: 45em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap {
    width: 92%;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .currency-select-wrap {
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  position: relative;
  z-index: 10;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .currency-select-wrap .text {
  color: rgb(139, 139, 139);
  font-size: 1.5rem;
  white-space: nowrap;
  font-weight: 500;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .currency-select-wrap .form-group .react-select-class {
  width: 15rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .currency-select-wrap .form-group .react-select-class > *:nth-child(3) {
  background-color: transparent;
  padding: 0.12rem 0.4rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .amount-detail-box {
  margin: 1.5rem 0rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .amount-detail-box .text {
  color: rgb(139, 139, 139);
  font-size: 1.5rem;
  white-space: nowrap;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 1rem;
}
@media only screen and (max-width: 45em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .amount-detail-box .text {
    flex-wrap: wrap;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .amount-detail-box .text span {
  font-weight: 700;
  color: rgb(1, 67, 69);
  font-size: 2.5rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap {
  height: calc(100vh - 24rem);
  overflow-y: scroll;
  width: 100%;
  border-radius: 1.2rem;
  border: 0.15rem solid rgb(233, 237, 244);
  background-color: #ffffff;
  padding: 0rem 5rem 0rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 45em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap {
    padding: 0rem 2rem;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap::-webkit-scrollbar {
  display: none;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #ffffff;
  padding: 0rem 0rem 0rem 0rem;
  overflow-y: scroll;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .submit-btn {
  margin-top: 3rem;
}
@media only screen and (max-width: 42em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box {
    max-height: unset;
    width: 100% !important;
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
    border-radius: 0.8rem;
    margin-bottom: 3rem;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box::-webkit-scrollbar {
  display: none;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap {
  flex: 1;
  overflow-y: scroll;
  width: 100%;
  border-radius: unset;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  border: unset;
  color: #020202;
  margin-bottom: unset;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 55em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap {
    padding-bottom: 1.5rem;
    margin-bottom: unset;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .scroll-wrap-box {
  flex: 1;
  overflow-y: scroll;
  padding-bottom: 3rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .scroll-wrap-box::-webkit-scrollbar {
  display: none;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box {
  display: flex;
  padding: 2rem 3rem;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 55em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box {
    padding: 1.5rem;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .left-box {
  display: unset;
  background-image: none;
  flex: 0 0 40%;
  border-right: unset;
  padding-top: 0rem;
}
@media only screen and (max-width: 55em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .left-box {
    flex: 0 0 25%;
  }
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .left-box .label {
  font-weight: 700;
  font-size: 1.25rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .left-box .value {
  font-size: 1.35rem;
  color: #676767;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box {
  flex: 1;
  padding: unset;
  align-items: flex-start;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box .date-img-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box .date-img-box .img-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box .date-img-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box .date-img-box .avatar-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #020202;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box .date-img-box .avatar-box span {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1.65rem;
  display: inline-block;
  color: #ffffff;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box .label {
  font-weight: 700;
  font-size: 1.35rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .box .right-box .value {
  font-size: 1.45rem;
  color: #676767;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .top-info {
  background-color: #f7f8f7;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .top-info .left-box {
  display: unset;
  background-image: none;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .top-info .left-box .value {
  margin-top: 0.6rem;
  color: #676767;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .top-info .right-box .value {
  margin-top: 0.6rem;
  color: #676767;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .detail-info .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .detail-info .right-box > *:last-child {
  text-align: right;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .detail-info .right-box > *:nth-child(2) {
  text-align: center;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .middle-info .left-box .label {
  margin-bottom: 1.5rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .middle-info .left-box .value {
  margin-top: 0.8rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .middle-info .right-box .label {
  margin-bottom: 1.5rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .middle-info .right-box .value {
  margin-top: 0.8rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .sum-detail-box {
  padding: 0.5rem 2.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .sum-detail-box .left-box .name {
  text-transform: capitalize;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .sum-detail-box .right-box {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .sum-detail-box .right-box > *:last-child {
  text-align: right;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .sum-detail-box .right-box > *:nth-child(2) {
  text-align: center;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .sum-detail-box .underline {
  width: 100%;
  margin: 1rem 0rem 0.4rem 0rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .sum-detail-box-underline .right-box {
  grid-template-columns: 1fr;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .underline-box {
  padding: 0rem 2.5rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  height: 0.1rem;
  background-color: #cccccc;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .note-wrap-box {
  padding: 0rem 2.5rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .note-wrap-box .note {
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.8rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .note-wrap-box .text {
  font-size: 1.45rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box {
  padding: 1.2rem 2rem;
  background-color: #f7f8f7;
  margin-top: auto;
  border-top: 0.1rem solid #cccccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .show-text {
  color: #676767;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .pagination-wrap {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .pagination-wrap .prev,
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .pagination-wrap .next {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  border: 0.175px solid #e5e6e6;
  background-color: #ffffff;
  padding: 0.7rem 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .pagination-wrap .prev .text,
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .pagination-wrap .next .text {
  text-transform: capitalize;
  font-size: 1.2rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .pagination-wrap .prev .icon,
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .invoice-preview-detail-wrap .pagination-box .pagination-wrap .next .icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap {
  display: grid;
  place-items: center;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0rem;
  gap: 1rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap .wrap .logo-name-box {
  margin-bottom: 1rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap .wrap .logo-name-box .img-box {
  width: 4rem;
  height: 4rem;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap .wrap .logo-name-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap .wrap .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #020202;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap .wrap .text {
  color: #676767;
  font-size: 1.35rem;
  line-height: 1.65rem;
  width: 85%;
  text-align: center;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box .content-cancel-wrap .wrap .text span {
  color: #755ae2;
  cursor: pointer;
}
.payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box-cancel-expire {
  width: 100%;
  margin: auto;
}
@media only screen and (max-width: 42em) {
  .payment-link-invoice-new-layout-wrap .content-layout .size-wrap-layout .version-two-public-invoice-wrap .invoice-public-content-main-wrap .preview-box-cancel-expire {
    width: 100%;
  }
}

.version-two-payment-link-public-wrap-index {
  width: 100%;
  display: grid;
  place-items: center;
}
@media only screen and (max-width: 48em) {
  .version-two-payment-link-public-wrap-index {
    width: 100%;
    align-items: flex-start;
    overflow-y: hidden;
  }
}
.version-two-payment-link-public-wrap-index .wrap-right-content {
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 45em) {
  .version-two-payment-link-public-wrap-index .wrap-right-content {
    padding-top: 11rem;
    padding-bottom: 1rem;
  }
}
.version-two-payment-link-public-wrap-index .wrap-right-content .address-currency-box {
  width: 100%;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
  -webkit-transition: max-height 0.3s;
  -moz-transition: max-height 0.3s;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .address-currency-box-show {
  max-height: 30rem;
  transition: max-height 0.3s linear-bezier(0, 1.05, 0, 1);
}
@media only screen and (max-width: 48em) {
  .version-two-payment-link-public-wrap-index .wrap-right-content {
    width: 95%;
    border-radius: 0.4rem;
    justify-content: center;
    margin-top: -0.5rem;
  }
}
.version-two-payment-link-public-wrap-index .wrap-right-content > * {
  padding: 2rem 2.5rem;
  width: 45rem;
}
@media only screen and (max-width: 48em) {
  .version-two-payment-link-public-wrap-index .wrap-right-content > * {
    padding: 2rem 1.5rem;
    width: 100%;
    border-radius: 0.4rem;
  }
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: unset;
  margin-top: -10rem;
}
@media only screen and (max-width: 48em) {
  .version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box {
    margin-top: unset;
  }
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box {
  align-self: center;
  display: grid;
  place-items: center;
  margin-bottom: 2rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box .avatar-box {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #020202;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box .avatar-box span {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1.65rem;
  display: inline-block;
  color: #ffffff;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box .name {
  margin-top: 1rem;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  color: rgb(1, 67, 69);
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box .img-box {
  width: 6rem;
  height: 6rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: 0.3rem solid rgb(238, 238, 238);
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box .img-box-default {
  background-color: #ffffff;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .outside-content-title-img-box .logo-name-box .img-box-default .img {
  width: 50%;
  height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content {
  background-color: #ffffff;
  border: 0.15rem solid rgb(233, 237, 244);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 4rem;
  border-radius: 1.2rem;
  box-shadow: 0.1rem 0.1rem 8rem 0rem, -0.1rem 0.1rem 8rem 0rem rgba(35, 42, 62, 0.15);
}
@media only screen and (max-width: 48em) {
  .version-two-payment-link-public-wrap-index .wrap-right-content .content {
    box-shadow: unset;
  }
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .back-text-box {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  align-self: flex-start;
  cursor: pointer;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .back-text-box:hover .back-icon .img-box {
  transform: translateX(-0.3rem);
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .back-text-box .back-icon {
  display: grid;
  place-items: center;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .back-text-box .back-icon .img-box {
  width: 1.2rem;
  height: 1.2rem;
  transition: transform 0.3s ease-in-out;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .back-text-box .back-icon .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .back-text-box .text {
  color: #020202;
  font-weight: 600;
  font-size: 1.6rem;
  transform: translateY(0.15rem);
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .title-detail-box {
  border-bottom: 0.15rem solid rgb(238, 238, 238);
  padding-bottom: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .title-detail-box .title {
  font-size: 2rem;
  font-weight: 600;
  color: rgb(1, 67, 69);
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .title-detail-box .text {
  font-size: 1.3rem;
  color: #676767;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .cur-amount-box-label .label {
  align-self: flex-start;
  margin-bottom: 0.4rem;
  font-size: 1.3rem;
  color: #020202;
  text-transform: capitalize;
  width: 100%;
  display: flex;
  align-items: center;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .cur-amount-box-label .cur-amount-box {
  display: grid;
  grid-template-columns: max-content 1fr;
  border: 0.1rem solid #ccc;
  background-color: #f7f8f7;
  border-radius: 0.8rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .cur-amount-box-label .cur-amount-box .form-group__green-dark .form-input {
  background-color: transparent;
  border: unset !important;
  border-radius: unset !important;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .cur-amount-box-label .cur-amount-box .cur-box-drop {
  border-radius: 0.8rem;
  border-right: 0.15rem solid #ccc;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  z-index: 10;
  position: relative;
  height: 100%;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .cur-amount-box-label .cur-amount-box .cur-box-drop .payment-cur {
  height: 100%;
  border-radius: 0.8rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .cur-amount-box-label .cur-amount-box .cur-box-drop .payment-cur .selected-obj-wrap {
  height: 100%;
  border: unset;
  border-radius: unset;
  border-radius: 0.8rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .cur-amount-box-label .cur-amount-box .cur-box-drop .payment-cur .select-list {
  top: unset;
  bottom: 110%;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .two-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
@media only screen and (max-width: 48em) {
  .version-two-payment-link-public-wrap-index .wrap-right-content .content .form-content-box .two-group {
    grid-template-columns: 1fr;
  }
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .link-title-box {
  background-color: #f7f8f7;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  border-radius: 0.8rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .link-title-box .link-title {
  font-weight: 700;
  font-size: 1.8rem;
  color: #020202;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .link-title-box .link-text {
  color: #676767;
  font-size: 1.3rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .logo-name-box {
  align-self: center;
  display: grid;
  place-items: center;
  margin-bottom: 2rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .logo-name-box .avatar-box {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  background-color: #020202;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .logo-name-box .avatar-box span {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 1.65rem;
  display: inline-block;
  color: #ffffff;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .logo-name-box .name {
  font-size: 1.55rem;
  font-weight: 700;
  text-align: center;
  color: #020202;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .logo-name-box .img-box {
  width: 4.5rem;
  height: 4.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  overflow: hidden;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content .logo-name-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap {
  display: grid;
  place-items: center;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0rem;
  gap: 1rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap .wrap .logo-name-box {
  margin-bottom: 1rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap .wrap .logo-name-box .img-box {
  width: 4rem;
  height: 4rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap .wrap .logo-name-box .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap .wrap .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #020202;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap .wrap .text {
  color: #676767;
  font-size: 1.35rem;
  line-height: 1.65rem;
  width: 85%;
  text-align: center;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-cancel-wrap .wrap .text span {
  color: #755ae2;
  cursor: pointer;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .top-detail-box {
  background-color: #f7f8f7;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.8rem;
  align-items: center;
  padding: 2.5rem 1.5rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .top-detail-box .img-wrap {
  margin-bottom: -1.5rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .top-detail-box .img-wrap .img-box {
  width: 12rem;
  height: 12rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .top-detail-box .img-wrap .img-box .img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .top-detail-box .title {
  font-size: 1.8rem;
  font-weight: 700;
  color: #020202;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .bottom-detail-box {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .bottom-detail-box .box {
  display: flex;
  justify-content: space-between;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .bottom-detail-box .box .label {
  font-size: 1.3rem;
  color: #676767;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .content-success-wrap .bottom-detail-box .box .value {
  text-align: right;
  font-size: 1.3rem;
  font-weight: 700;
  color: #020202;
}
.version-two-payment-link-public-wrap-index .wrap-right-content .btn-box-two > *:first-child {
  width: 100%;
}/*# sourceMappingURL=PayementLinkInvoiceLayout.css.map */